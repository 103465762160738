const breakpoints = {
    xs: 0,
    sm: 600,
    md: 981,
    lg: 1200,
    xl: 1334,
    full: '100%',
};

export default breakpoints;
