import Header from '@/Components/Layouts/Header.jsx';
import YaKCookiesModal from '@/Components/Modals/Themed/YaKCookiesModal.jsx';
import { default as couleur_villas_theme } from '@/Themes/couleur-villas/theme.js';
import { default as eureka_theme } from '@/Themes/eureka/theme.js';
import { default as ya_k_theme } from '@/Themes/ya-k/theme.js';
import { usePage } from '@inertiajs/react';
import React, { memo } from 'react';

const MainLayout = ({ header = true, page_name, children }) => {
    const is_auth_page = page_name.includes('Auth');
    const { url_theme, auth } = usePage().props;
    const user = auth.user;

    let theme = eureka_theme;
    switch (url_theme) {
        case 'ya-k':
            theme = ya_k_theme;
            break;
        case 'couleur-villas':
            theme = couleur_villas_theme;
            break;
    }

    const style = {
        backgroundColor: is_auth_page ? theme.palette.background.light : 'white',
    };

    const handleAcceptCookies = () => {
        if (!document.getElementById('gtm-script')) {
            document.location.reload(true);
        }
    };

    return (
        <>
            {header && <Header brand={url_theme} user={user} />}
            <main id="content" style={style}>
                {children}
            </main>

            {url_theme === 'ya-k' && <YaKCookiesModal onAccept={handleAcceptCookies} />}
        </>
    );
};
export default memo(MainLayout);
