import ApplicationLogo from '@/Components/ApplicationLogo.jsx';
import { getCookie, setCookie } from '@/Scripts/Common/cookies.jsx';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Link as MuiLink,
    Stack,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { useState } from 'react';

const COOKIE_NAME = 'ya-k_cookie_notice_accepted';

const YaKCookiesModalLogo = styled(ApplicationLogo)({
    objectFit: 'contain',
    width: '100%',
    height: 'auto',
});

const YaKCookiesModal = ({ onAccept, onRefuse }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const cookie_value = getCookie(COOKIE_NAME);
    const cookie_is_set = ['accepted', 'refused'].includes(cookie_value);
    const [open, setOpen] = useState(!cookie_is_set);

    const handleAccept = () => {
        setCookie(COOKIE_NAME, 'accepted', 365);
        setOpen(false);
        onAccept && onAccept();
    };

    const handleRefuse = () => {
        setCookie(COOKIE_NAME, 'refused', 365);
        setOpen(false);
        onRefuse && onRefuse();
    };

    const RenderText = ({ content, variant, sx }) =>
        typeof content === 'string' ? (
            <Typography variant={variant} sx={sx}>
                {content}
            </Typography>
        ) : (
            content()
        );

    return (
        <Dialog open={open} fullScreen={fullScreen} scroll="paper">
            <>
                <DialogContent>
                    <Box sx={{ pt: 2, px: 4 }}>
                        <Box sx={{ width: 190, height: 131, mx: 'auto', mt: 2, mb: 4 }}>
                            <YaKCookiesModalLogo />
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site. En
                            cliquant sur "j'accepte", nous considérerons que vous acceptez l'utilisation des cookies.
                            Vous pouvez à tout moment modifier vos préférences. Pour plus d'informations, veuillez
                            consulter{' '}
                            <MuiLink
                                href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser"
                                target="_blank"
                            >
                                cette page.
                            </MuiLink>
                        </Box>
                    </Box>
                </DialogContent>
                <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 2, maxWidth: 250, mx: 'auto', px: 2, pb: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleAccept}>
                        <RenderText
                            content={"J'accepte"}
                            variant="body1"
                            sx={{
                                flex: '1 1 auto',
                            }}
                        />
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleRefuse}
                        sx={{
                            flex: '1 1 auto',
                        }}
                    >
                        <RenderText content={'Je refuse'} variant="body1" />
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        href="https://www.yak-construire.com/donnees-personnelles/"
                        target="_blank"
                        sx={{ width: '100%' }}
                    >
                        <RenderText content={'En savoir plus'} variant="body1" />
                    </Button>
                </Stack>
            </>
        </Dialog>
    );
};
export default YaKCookiesModal;
