import palette_utils from '@/Themes/utils/palette_utils.js';

const palette = {
    primary: palette_utils.ya_k_primary,
    secondary: palette_utils.text,
    white: palette_utils.white,
    text: {
        main: '#000000',
        light: '#9f9f9f',
        contrastText: '#fffff',
    },
    background: palette_utils.background,
    border: palette_utils.border,
};

export default palette;
